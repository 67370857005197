<template>
  <base-sticky v-slot="{ isStuck }" :margin="header.sticky.margin" name="headerPLP">
    <div
      v-style:top="{ sm: '0', lg: pxToRem(header.sticky.offset - 1) }"
      class="sticky z-header grid cols-[1fr_auto] gap-x-4 bg-white px-4 py-2 lg:gap-y-2 lg:px-6"
      :class="{ 'shadow-bottom': isStuck }"
    >
      <h1 class="body-1 lg:col-span-2">
        {{ title }}
      </h1>
      <div class="flex items-center <lg:row-span-2">
        <base-button
          aria-controls="plp-filters"
          :aria-expanded="filtersShown"
          class="flex items-center gap-1"
          @click="onFiltersToggle"
        >
          <span class="<lg:sr-only">{{ filtersShown ? $t.hideFilters : $t.showFilters }}</span>
          <vf-icon name="filters" size="md" />
        </base-button>
      </div>

      <div aria-live="polite">
        <p class="body-4 lg:body-3">
          {{ total }} {{ $pluralize($t.items, total) }}
        </p>
      </div>
    </div>
    <panel-filters v-slot="attrs">
      <slot v-bind="attrs" />
    </panel-filters>
  </base-sticky>
</template>

<script lang="ts" setup>
import type { Catalog, Search } from '#root/api/clients/product/data-contracts'
import type { FiltersMap } from '#types/filters'

const props = defineProps<{
  list: Catalog | Search | null
  productsLoading: boolean
  nextPageLoading: boolean
  sideFilters: boolean
  pickupFilters: boolean
  title?: string
  filters: FiltersMap
  filtersQuery: Record<string, string[]>
  sortOptions: { label: string, value: string }[]
  sortState: string
  storeFilterState?: string
}>()

const PanelFilters = createPanel('filters')

const { $t, $viewport } = useNuxtApp()

const header = useHeaderStore()
const filtersShown = defineModel('filtersShown')
const total = computed(() => props.list?.total || 0)

const onFiltersToggle = () => {
  if ($viewport.lg)
    filtersShown.value = !filtersShown.value
  else
    PanelFilters.open()
}
</script>
