<template>
  <div data-scroll-el="productList" />
  <product-list-header
    v-slot="attrs"
    v-model:filtersShown="filtersShown"
    :filters
    :filters-query="filtersQuery"
    :list
    :next-page-loading="nextPageLoading"
    :pickup-filters="pickupFilters"
    :products-loading="productsLoading"
    :side-filters="sideFilters"
    :sort-options="sortOptions"
    :sort-state="`${sortState}`"
    :store-filter-state="storeFilterState"
    :title="headerTitle"
    @change="selectAndFilterBy"
    @change-store="filterByStore"
    @clear="clearFilters"
    @remove="removeFilter"
    @sort="selectAndSortBy"
  >
    <dialog-filters
      v-bind="attrs"
      :currency
      :filters
      :filters-query="filtersQuery"
      :heading="headerTitle || $t.filterAndSort"
      :loading="productsLoading || nextPageLoading"
      :pickup-filters="pickupFilters"
      :sort-options="sortOptions"
      :sort-state="sortState"
      :store-filter-state="storeFilterState"
      :total
      @change="selectAndFilterBy"
      @change-store="filterByStore"
      @clear="clearFilters"
      @remove="removeFilter"
      @sort="selectAndSortBy"
    />
  </product-list-header>
  <div :class="brandClasses.outerGridWrapper">
    <div
      v-if="gridSizeSelector && (list?.products.length || hasFilters)"
      :class="brandClasses.gridSizeSelector"
    >
      <base-button
        v-for="(count, label) in { [$t.defaultView]: '4', [$t.compactView]: '6' }"
        :key="count"
        :aria-pressed="colsLg === count"
        @click="colsLg = count"
      >
        <span class="sr-only">{{ label }}</span>
        <span
          class="block cursor-pointer b-b duration peer-focus-visible:outline-auto "
          :class="colsLg === count ? 'b-grey-10 c-grey-10' : 'b-transparent c-grey-40 hover:b-grey-40'"
        >
          <vf-icon :name="`square-${count}`" size="md" />
        </span>
      </base-button>
    </div>

    <div :class="brandClasses.outerGrid">
      <base-sticky :margin="headerPLPHeight" name="plpSideFilters" position="top">
        <product-list-filters
          v-if="sideFilters"
          id="plp-filters"
          class="sticky h-screen shrink-0 self-start overflow-x-hidden overflow-y-auto pb-4 pr-2 scrollbar-sm duration <lg:hidden b-t b-grey-80"
          :class="{ 'hidden': !filtersShown }"
          :currency
          :filters
          :filters-query="filtersQuery"
          :loading="productsLoading"
          :pickup-filters="pickupFilters"
          :side-filters="sideFilters"
          :sort-options="sortOptions"
          :sort-state="`${sortState}`"
          :store-filter-state="storeFilterState"
          :style="{
            top: pxToRem(headerPLPHeight),
            maxHeight: `calc(100vh - ${filtersTop})`,
          }"
          :total
          @change="selectAndFilterBy"
          @change-store="filterByStore"
          @clear="clearFilters"
          @remove="removeFilter"
          @sort="selectAndSortBy"
        >
          <template #title>
            <div class="b-b b-grey-80 py-2 subtitle-3 hidden">
              {{ $t.filterAndSort }}
            </div>
          </template>
        </product-list-filters>
      </base-sticky>
      <div :class="[brandClasses.innerGridWrapper, { 'lg:col-span-3': sideFilters }]">
        <template v-if="pickupFilters && sideFilters && pickupFilterAboveList">
          <base-sticky v-slot="{ isStuck }" name="pickupFiltersPLP">
            <div
              class="sticky top-0 bg-white"
              :class="[
                { '<lg:hidden': sideFilters },
                isStuck ? 'z-50' : 'z-2',
              ]"
            >
              <filter-pickup :class="isStuck ? 'pt-4' : 'mb-2'" :model-value="storeFilterState" @update:model-value="filterByStore($event)" />
            </div>
          </base-sticky>
        </template>
        <product-list-no-results v-if="!productsLoading && !products.length" :class="brandClasses.noResults" :pickup-filters="pickupFilters" />
        <!-- Product grid -->
        <base-grid v-else ref="gridRef" :cols :gap-x="grid.gaps.x" :gap-y="grid.gaps.y">
          <!-- hydration mode is set to visible because we need to calculate the number of hidden product variants -->
          <template v-if="!productsLoading">
            <template v-for="(product, i) in products" :key="product.id">
              <base-lazy-hydrate
                v-if="i < renderedProducts"
                :interaction="['mouseenter', 'focus', 'touchstart']"
                :when="hydrationMode"
              >
                <product-card
                  v-bind="{ breadcrumbs, hideRating, product, quickshopMode, storeFilterState, trackingCategory }"
                  :class="{ '<md:col-span-2': showFocusedProduct && product.focusedProduct }"
                  :image-fetch-priority="i < +colsLg ? 'high' : 'auto'"
                  :lazy="i >= +colsLg"
                  :show-discount-percentage="$feature.showDiscountPercentageOnCatalog"
                  :show-swatches="$feature.showSwatchesInProductList"
                />
              </base-lazy-hydrate>

              <!-- Makes sure all product links are available after SSR and can be crawled -->
              <base-lazy-hydrate v-else when="visible">
                <base-link ref="lazyLoadRefs" :aria-label="product.name" :to="product.url">
                  <product-card-skeleton :show-swatches="$feature.showSwatchesInProductList" />
                </base-link>
              </base-lazy-hydrate>
            </template>

            <slot v-bind="{ cols }" />
          </template>
          <!-- Skeleton -->
          <template v-if="productsLoading || nextPageLoading">
            <product-card-skeleton
              v-for="i in (nextPageLoading ? nextPageSize : productsPerPage)"
              :key="`product-${i}`"
              :show-swatches="$feature.showSwatchesInProductList"
            />
          </template>
        </base-grid>

        <!-- Pagination Skeleton -->
        <product-list-pagination-skeleton v-if="productsLoading" />
        <!-- Pagination -->
        <product-list-pagination
          v-else-if="products.length"
          :class="brandClasses.pagination"
          :current="products.length"
          :page-size="nextPageSize"
          :total
          @load-more="loadMore"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { QuickshopMode } from '#types/catalog'
import type { Catalog, Search } from '#root/api/clients/product/data-contracts'
import type { Responsive } from '#types/common'
import type { BaseGrid as BaseGridType } from '#components'

const props = defineProps<{
  list: Catalog | Search | null
  productsLoading: boolean
  nextPageLoading: boolean
  trackingCategory?: string
  sideFilters: boolean
  hideRating?: boolean
  quickshopMode: QuickshopMode
  gridSizeSelector: boolean
  pickupFilters: boolean
  headerTitle?: string
}>()

const emit = defineEmits<{
  'load-more': []
}>()

defineSlots<{
  default: (props: { cols: Responsive<number> }) => void
}>()

const {
  brandClasses,
  eagerLoadedCards,
  grid,
  hydrationMode,
  pickupFilterAboveList,
  productsPerPage,
  scrollToElementOptions,
} = useAppConfig().components.product.list
const { $t, $feature } = useNuxtApp()
const route = useRoute()

const {
  clearFilters,
  filterBy,
  filterByStore,
  filters,
  filtersQuery,
  removeFilter,
  sortBy,
  sortOptions,
  sortState,
  storeFilterState
} = useFilters(
  () => props.list?.filters || [],
  () => scrollToElement('productList', scrollToElementOptions)
)

const stickyState = useSticky()
const header = useHeaderStore()

const showFocusedProduct = computed(() =>
  $feature.showFocusedProductOnRoutes.some((pattern) => convertPatternToRegex(pattern).test(route.fullPath)))

const total = computed(() => props.list?.total || 0)
const currency = computed(() => props.list?.currency || 'USD')
const breadcrumbs = computed(() => props.list?.breadcrumbs || [])
const colsLg = ref(`${props.sideFilters ? grid.cols.lg - 1 : grid.cols.lg}`)
const products = computed(() => props.list?.products || [])
const cols = computed(() => ({ ...grid.cols, lg: +colsLg.value }))
const nextPageSize = computed(() => Math.min(productsPerPage, total.value - products.value.length))
const hasFilters = computed(() => !!Object.keys(filters.value)?.length)
const filtersShown = ref(false)
const gridRef = ref<typeof BaseGridType>()
const lazyLoadRefs = ref<HTMLAnchorElement[]>([])
const productsToRender = ref(eagerLoadedCards)
const renderedProducts = computed(() => Math.min(productsToRender.value, products.value.length))
const { height: headerPLP } = useElementBounding(() => stickyState.value.headerPLP)

const headerPLPHeight = computed(() => headerPLP.value + (header.pageScrolledUp ? header.height.header : 0))

const { top: gridTop } = useElementBounding(() => gridRef.value?.$el)
const filtersTop = computed(() => pxToRem(headerPLPHeight.value || gridTop.value))

const loadMore = () => {
  const lastCard = gridRef.value?.$el.lastElementChild.querySelector('[data-test-id="product-card-add"]')
  emit('load-more')
  lastCard?.focus()
}

const lazyLoadChunk = (entries: IntersectionObserverEntry[]) => entries.some(({ isIntersecting, boundingClientRect }) =>
  isIntersecting // Prevents the re-rendering of the grid from triggering the logic prematurely
  || boundingClientRect.bottom < 0 // Forces any observed refs above the viewport after SSR to be triggered
)

useIntersectionObserver(lazyLoadRefs, (entries) => {
  if (lazyLoadChunk(entries)) productsToRender.value += eagerLoadedCards
})

const selectAndSortBy = (sort: string) => {
  sortState.value = sort
  sortBy()
}

const selectAndFilterBy = ({ code, selected }: { code: string, selected: string[] }) => {
  filters.value[code].selected = selected
  filterBy(code)
}
</script>
